<template>
  <van-form @submit="onSubmit">
    <van-cell-group title="叫号信息" inset>
      <van-field
          v-model="id"
          name="id"
          v-show="false"
      ></van-field>
      <Picker
          name="vehicle.id"
          label="选择我关联的车辆"
          placeholder="选择我关联的车辆"
          valueField="id"
          displayField="carCodeHead"
          v-model:value="this.$data['vehicle.id']"
          :rules="[{ required: true, message: '请选择车辆' }]"
          :defConfig="{
               portName: 'tms.TmsVehicle.vapp.getMyDispatchCar',
           }"
          required
      ></Picker>
      <Picker
          name="custom.id"
          label="选择客户订单"
          placeholder="选择客户订单"
          valueField="id"
          displayField="customOrder.code"
          v-model:value="this.$data['custom.id']"
          :rules="[{ required: true, message: '请选择客户订单' }]"
          :defConfig="{
               portName: 'tms.transport.dispatchOrderVehicleCustom.getList',
               data:{'vehicle.id': this.$data['vehicle.id'],
                    'vapp':'vapp'}
           }"
          @onConfirm="onSupplierConfirm"
          required
      ></Picker>
      <Picker
          name="warehouse.id"
          label="仓库"
          placeholder="仓库"
          v-model:value="this.$data['warehouse.id']"
          :defConfig="{
               portName: 'tms.transport.dispatchOrderVehicleCustom.getWarehouseList',
               data:{'vehicle.id':this.$data['vehicle.id'],'customOrder.id':this.$data['custom.id']}
           }"
          :rules="[{ required: true, message: '请选择仓库' }]"
          required
      ></Picker>
      <Picker
          name="loadingTypeDic.id"
          label="装卸类型"
          placeholder="装卸类型"
          v-model:value="this.$data['loadingTypeDic.id']"
          :defConfig="{
                 portName: 'system.foundation.Dic.getChildDicsByCode',
                    data: {code: 'CALL_QUEUE_LOAD_STATUS'}
           }"
          :rules="[{ required: true, message: '请选择装卸类型' }]"
          required
      ></Picker>

      <van-field
          v-model="this.$data['platform']"
          name="platform"
          label="月台"
          placeholder="月台"
          required
          :rules="[{ required: true, message: '月台' }]"
      ></van-field>
      <van-button round block type="primary" native-type="submit">
        我要叫号入场
      </van-button>
    </van-cell-group>
  </van-form>
  <GridList title="我的叫号" port-name="tms.queue.queueLog.getList"
            :request-data="{'vapp': 'vapp'}"
            :dataList="dataList" style="margin: 4.26667vw;"
            ref="grid">
    <ListCard
        v-for="item in dataList"
        :key="item.id"
        :canDel=false
        :id="item['callQueue.id']"
        :onCancel="onCancel"
        :canCancel=true
    >
      <div class="item" >
        <!--          <van-image class="image" round  :src="require('../../assets/img/派车.png')"/>-->
        <div class="content">
          <div class="title fs16">{{ '仓库名称： '+item['callQueue.wareHouse.name'] }}</div>
          <!--            <div class="tags fs12"><span style="color: #129d67">{{'派单状态: '+ item['dispatchOrder.dispatchOrderRfq.rfqStatusDic.name'] }}</span></div>-->
          <div class="time fs16">{{ '叫号： '+item['callQueue.callOrder'] }}</div>
          <div class="time fs16">{{ '车牌： '+item['callQueue.vehicle.carCodeHead'] }}</div>
          <div class="time fs16">{{ '叫号状态： '+item['callStatusDic.name'] }}</div>
          <div class="time fs16">{{ '装卸类型： '+item['callQueue.loadingTypeDic.name'] }}</div>
          <div class="time fs16">{{ '月台： '+item['callQueue.platform'] }}</div>
          <div class="time fs16">{{ '叫号时间： '+item['createTime'] }}</div>
          <div class="time fs16">{{ '叫号人： '+item['createUser.name'] }}</div>
        </div>
        <div class="fs12">          <van-tag  :type="getTagType(item['callStatusDic.name'])">{{ item['callStatusDic.name']}}</van-tag>
          <!--            <van-tag plain type="warning"> {{ item['dispatchOrder.dispatchOrderRfq.id'] ? "已派单":"未派单" }}</van-tag>-->
        </div>
      </div>
    </ListCard>
  </GridList>

</template>

<script>
//osp.car.VehicleReserve.getVehicleReserves


import Picker from "@/components/Picker.vue";
import {Toast} from "vant";
import ListCard from "@/components/ListCard.vue";
import GridList from "@/components/GridList.vue";

export default {
  components: {
    Picker,ListCard, GridList
  },
  data() {
    return {
      id:'',
      "warehouse.id":'',
      'loadingTypeDic.id':'',
      'vehicle.id':'',
      'custom.id':'',
      platform:'',
      dataList: [],
    };
  },
  created() {
    //this.getRecentCar();
  },

  methods: {
    onSubmit(values) {
      var option = {
        portName: 'tms.queue.callQueue.saveEntity',
        data: values,
        needLoading: false,
        successCallback: (data) => {
          this.$refs.grid.onRefresh();
          // this.id = data.data.id;
        }
      };
      try {
        this.$sapi.callPort(option);
      } catch (error) {
        Toast("保存失败,请检查数据")
      }

    },
    onDel(){

    },
    onCancel: function (id) {

      this.$dialog.confirm({
          title: '',
          message: '是否确定取消该叫号？',
        })
            .then(() => {
              var option = {
                portName: 'tms.queue.callQueue.cancel',
                data: {ids: id},
                needLoading: false,
                successCallback: () => {
                  this.$notify({type: 'success', message: '取消成功'})
                  this.$refs.grid.onRefresh();
                }
              }
              this.$sapi.callPort(option);
            })
            .catch(() => {});

    },
    getTagType(code) {
      switch (code) {
        case '正在叫号':
          return 'primary';
        case '未叫号':
          return 'success ';
        case '已过号':
          return 'danger';
        default:
          return 'warning';
      }
    },
    onSupplierConfirm(value) {
      this.$data['warehouse.id'] = "";
    },
    //获取最近车辆
    getRecentCar() {
      var visitor = this.$sapi.storage.getItemJson('visitor');
      var visitorID = visitor.id;
      var option = {
        portName: 'osp.car.VehicleReserve.getVehicleReserves',
        data: {'visitor.id': visitorID},
        needLoading: false,
        pageIndex: 0,
        pageSize: 1,
        successCallback: (data) => {
          var entity = data.entities[0];
          if (!_.isEmpty(entity)) {
            this.carNum = entity['carNum'];
            this.brand = entity['brand'];
          }
        }
      }
      this.$sapi.callPort(option);
    },
  },
};

</script>

<style scoped>
.item {
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  box-shadow: 0 0 2px #ddd;
  align-items: center;
  padding: 10px;
  margin: 2px;
}

.item + .item {
  margin-top: 10px;
}

.item > .image {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}
.title {
  display: block;
  text-decoration: none;
  line-height: 1.5;
  margin: 0px 0px;
  background-image: -webkit-linear-gradient(
      left,
      #3498db,
      #f47920 10%,
      #d71345 20%,
      #f7acbc 30%,
      #3498db
  );
  color: transparent;
  -webkit-background-clip: text;
  background-size: 200% 100%;
  animation: slide 5s infinite linear;
  font-size: 16px;
}

.item > .title {

}

.item .status_green {
  color: #519874;
}

.item .status_red {
  color: #f83817;
}

.item > .content {
  width: 500px;

}



.item .time {
  color: #666;
}
</style>